<template>
  <div class="flex h-full w-full flex-col gap-4 p-4">
    <div class="flex shrink-0 flex-row">
      <h2 class="flex-1 text-2xl font-bold leading-6 text-gray-900">
        Release Note - {{ release?.version }}
      </h2>
      <div class="shrink-0"></div>
    </div>
    <div class="flex flex-1 flex-col gap-3" v-if="release">
      <div class="grid grid-cols-1 gap-x-5 gap-y-3 md:grid-cols-2">
        <div>
          <BaseShowEditInput v-model="release.version" edition-mode />
        </div>
        <div class="col-start-1">
          <BaseShowEditDatePickerv2 v-model:date="release.date" edition-mode />
        </div>
      </div>
      <div>
        <h3 class="flex-1 text-base font-bold leading-6 text-gray-900">
          Release Notes
        </h3>
        <div
          v-for="(release_note, index) in release.release_notes"
          :key="release_note.id"
        >
          <div class="grid grid-cols-1 gap-x-5 gap-y-3 md:grid-cols-2">
            <div>
              <SelectComponent
                name="type"
                :values="releaseNoteTypes"
                v-model="release.release_notes[index].type"
                required
              />
            </div>
            <div class="col-span-2">
              <BaseShowEditTranslation
                v-model:translations="release.release_notes[index].title"
              />
            </div>
            <div class="col-span-2 col-start-1">
              <BaseShowEditTranslation
                v-model:translations="release.release_notes[index].description"
                field-type="textarea"
              />
            </div>
          </div>
        </div>
        <div>
          <BaseButton
            :title="$t('button.add')"
            color="primary"
            @click="addReleaseNote"
          />
        </div>
        <div class="flex justify-end">
          <BaseButton :title="$t('button.save')" @click="submitReleaseNote" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import BaseShowEditTranslation from '@c/addf-package/components/BaseShowEditInput/BaseShowEditTranslation.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'

export default {
  name: 'TheReleasesPage',
  components: {
    SelectComponent,
    BaseButton,
    BaseShowEditTranslation,
    BaseShowEditDatePickerv2,
    BaseShowEditInput
  },
  data() {
    return {
      release: null,
      releaseNoteTypes: [
        {
          id: 'fixe',
          name: 'Fixe'
        },
        {
          id: 'change',
          name: 'Change'
        }
      ]
    }
  },
  props: {
    releaseId: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.fetchRelease()
  },
  methods: {
    ...mapActions({
      processFetchReleaseAction: 'saManagement/fetchRelease',
      processUpdateReleaseAction: 'saManagement/updateRelease'
    }),
    fetchRelease() {
      this.processFetchReleaseAction({
        release_id: this.releaseId
      }).then((response) => {
        this.release = response.release
      })
    },
    addReleaseNote() {
      this.release.release_notes.push({
        title: {},
        description: {},
        type: 'fixe',
        release_id: this.release.id
      })
    },
    submitReleaseNote() {
      if (this.release.id) {
        this.processUpdateReleaseAction({
          release_id: this.release.id,
          data: this.release
        }).then((response) => {
          this.release = response.release
        })
      }
    }
  }
}
</script>
