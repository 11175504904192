<template>
  <div class="flex h-full w-full flex-col divide-y shadow sm:rounded-lg">
    <div class="flex shrink-0 items-center justify-between px-4 py-3">
      <h3 class="text-3xl font-bold leading-6 text-gray-900">Database</h3>
      <div>
        <BaseButton
          color="warning"
          icon="database"
          title="dump DB"
          @click="handleDumpDBClick"
        />
      </div>
    </div>
    <div class="flex-1 px-4 py-5 sm:px-6">
      <ul
        v-if="dumps.length"
        role="list"
        class="divide-y divide-gray-200 rounded-md border border-gray-200"
      >
        <DumpComponent v-for="dump in dumps" :key="dump.id" :dump="dump" />
      </ul>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import DumpComponent from '@/views/sa_management/database/DumpComponent.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'TheSAMDatabasePage',
  components: { BaseButton, DumpComponent },
  data() {
    return {
      dumps: []
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      auth: 'auth/getAuth'
    })
  },
  mounted() {
    this.processFetchDumpListAction().then((response) => {
      this.dumps = response.dumps
    })
  },
  methods: {
    ...mapActions({
      processFetchDumpListAction: 'saManagement/fetchDumpList',
      processDumpDbAction: 'saManagement/dumpDb'
    }),
    handleDumpDBClick() {
      this.processDumpDbAction({
        successCallback: () => {
          this.$h.toastify('cette sauvegarde a été effectuée avec succés', {
            className: 'toastify-content success'
          })
        }
      })
    }
  }
}
</script>
