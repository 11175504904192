import TheSAMCommandsPage from '@/views/sa_management/commands/TheSAMCommandsPage.vue'
import TheSAMDatabasePage from '@/views/sa_management/database/TheSAMDatabasePage.vue'
import TheGeneralSaManagementsPage from '@/views/sa_management/general/tabs/general/TheGeneralSaManagementsPage.vue'
import TheImportPage from '@/views/sa_management/import/TheImportPage.vue'
import TheListImport from '@/views/sa_management/import/TheListImport.vue'
import ImportEditComponent from '@/views/sa_management/import/component/ImportEditComponent.vue'
import TheReleasesPage from '@/views/sa_management/releaseNote/TheReleasesPage.vue'
import TheReleasePage from '@/views/sa_management/releaseNote/TheReleasePage.vue'

export const saManagementGeneralRoutes = [
  {
    path: '/sa-management',
    name: 'management_general_route',
    redirect: { name: 'sa_management_home_route' },
    children: [
      {
        path: '',
        component: TheGeneralSaManagementsPage,
        name: 'sa_management_home_route'
      },
      {
        path: 'release',
        children: [
          {
            path: '',
            name: 'management_release_route',
            component: TheReleasesPage
          },
          {
            path: ':releaseId',
            name: 'management_release_note_list_route',
            component: TheReleasePage,
            props: true
          }
        ]
      },
      {
        path: 'commands',
        name: 'management_commands_route',
        component: TheSAMCommandsPage
      },
      {
        path: 'database',
        name: 'management_db_route',
        component: TheSAMDatabasePage
      },
      {
        path: 'import',
        name: 'management_import_route',
        component: TheImportPage,
        redirect: { name: 'import_list_route' },
        children: [
          {
            path: '',
            name: 'import_list_route',
            component: TheListImport
          },
          {
            path: 'new',
            name: 'import_new_import_route',
            component: ImportEditComponent,
            props: true
          },
          {
            path: ':importId',
            name: 'import_edit_import_route',
            component: ImportEditComponent,
            props: true
          }
        ]
      }
    ]
  }
]
