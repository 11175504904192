import Country from '../../../assets/enums/config/Country'
import { mapGetters } from 'vuex'
import eventType from '@/assets/enums/events/EventType'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      editionMode: 'dossier/getEditionMode',
      dossier: 'dossier/getDossier',
      settings: 'metadata/getInitialMetadata',
      defaultCountry: 'metadata/getDefaultCountry'
    }),
    firstMenu() {
      const funeralOrder = this.settings.settings.events_order.funeral || []

      const reorderSubItems = (subItems) => {
        if (!subItems || !funeralOrder.length) return subItems

        return funeralOrder
          .map((type) => {
            const item = subItems.find((i) => i.key === type)
            const label = item
              ? item.anchor.replace('#', '') + '_enabled'
              : null
            if (item && label && this.settings.settings[label]) {
              return item
            }
            return null
          })
          .filter(Boolean)
      }

      return [
        {
          label: this.$t('global.summary'),
          routeName: 'dossier_form_edit_route',
          icon: ['fas', 'house-blank'],
          gate: this.cIsEditView,
          subItems: null,
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.informations'),
          routeName: this.cInformationRoute,
          icon: ['fas', 'user'],
          gate: this.cIsEditView || this.cIsCreateView,
          subItems: [
            {
              label: this.$t('global.informations'),
              anchor: '#informations'
            },
            {
              label: this.$t('global.deceased'),
              anchor: '#deceased'
            },
            {
              label: this.$t('global.profession'),
              anchor: '#profession'
            },
            {
              label: this.$t('global.marital_status'),
              anchor: '#marital_status'
            },
            {
              label: this.$t('global.parents'),
              anchor: '#parent'
            },
            {
              label: this.$t('global.children'),
              anchor: '#children'
            },
            {
              label: this.$t('global.death'),
              anchor: '#death'
            },
            {
              label: this.$t('global.others'),
              anchor: '#other'
            }
          ],
          formType: ['normal', 'light'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.contacts'),
          routeName: this.editionMode
            ? 'dossier_form_contact_route_edit'
            : 'dossier_form_contact_route',
          icon: ['fas', 'users'],
          gate: this.cIsEditView,
          subItems: null,
          formType: ['normal', 'light'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.remains'),
          routeName: this.editionMode
            ? 'dossier_form_remains_route_edit'
            : 'dossier_form_remains_route',
          icon: ['fas', 'user-tag'],
          gate: this.cIsEditView,
          subItems: [
            {
              label: this.$t(`enums.event.type.${eventType.TRANSFERT}`),
              anchor: '#transfert'
            },
            {
              label: this.$t(`enums.event.type.${eventType.THANATO}`),
              anchor: '#thanato'
            },
            {
              label: this.$t(`attributes.pacemaker`),
              anchor: '#pacemaker'
            },
            {
              label: this.$t(`enums.event.type.${eventType.COFFIN_PLACING}`),
              anchor: '#coffin_placing'
            },
            {
              label: this.$t(`enums.event.type.${eventType.COFFIN_CLOSING}`),
              anchor: '#coffin_closing'
            },
            {
              label: this.$t(`attributes.other_infos`),
              anchor: '#other_infos'
            }
          ],
          formType: ['normal', 'light'],
          countries: [Country.FRANCE]
        },
        {
          label: this.$t('global.funerals'),
          routeName: this.editionMode
            ? 'dossier_form_funeral_route_edit'
            : 'dossier_form_funeral_route',
          icon: ['fas', 'user-tie'],
          gate: this.cIsEditView,
          subItems: reorderSubItems([
            {
              label: this.$t(`enums.event.type.DECL`),
              anchor: '#declaration',
              key: 'DECL'
            },
            {
              label: this.$t(`enums.event.type.CRE`),
              anchor: '#cremation',
              key: 'CRE'
            },
            {
              label: this.$t(`enums.event.type.CER`),
              anchor: '#ceremony',
              key: 'CER'
            },
            {
              label: this.$t(`enums.event.type.WAK`),
              anchor: '#wake',
              key: 'WAK'
            },
            {
              label: this.dossier?.events?.find((event) => event.type === 'CRE')
                ? this.$t('attributes.burial_type')
                : this.$t(`enums.event.type.BUR`),
              anchor: '#burial',
              key: 'BUR'
            },
            {
              label: this.$t(`enums.event.type.BDR`),
              anchor: '#body_raising',
              key: 'BDR'
            },
            {
              label: this.$t(`enums.event.type.ASR`),
              anchor: '#ashes_recovery',
              key: 'ASR'
            },
            {
              label: this.$t(`enums.event.type.REU`),
              anchor: '#reunion',
              key: 'REU'
            },
            {
              label: this.$t(`enums.event.type.VIS`),
              anchor: '#visit',
              key: 'VIS'
            },
            {
              label: this.$t(`enums.event.type.ROO`),
              anchor: '#room_occupancy',
              key: 'ROO'
            },
            {
              label: this.$t(`enums.event.type.REC`),
              anchor: '#reception',
              key: 'REC'
            },
            {
              label: this.$t(`enums.event.type.EXH`),
              anchor: '#exhumation',
              key: 'EXH'
            }
            //pas encore ajouté au formulaire du dossier
            // { label: this.$t(`enums.event.type.EXH`), anchor: '#exhumation', key: 'EXH' }
          ]),
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.coffin_and_products'),
          routeName: this.editionMode
            ? 'dossier_form_coffin_and_product_route_edit'
            : 'dossier_form_coffin_and_product_route',
          icon: ['fas', 'coffin'],
          gate: this.cIsEditView,
          subItems: null,
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.remains'),
          routeName: this.editionMode
            ? 'dossier_form_remains_route_edit'
            : 'dossier_form_remains_route',
          icon: ['fas', 'user-tag'],
          gate: this.cIsEditView,
          subItems: [
            {
              label: this.$t(`enums.event.type.${eventType.TRANSFERT}`),
              anchor: '#transfert'
            },
            {
              label: this.$t(`enums.event.type.${eventType.THANATO}`),
              anchor: '#thanato'
            },
            {
              label: this.$t(`attributes.pacemaker`),
              anchor: '#pacemaker'
            },
            {
              label: this.$t(`enums.event.type.${eventType.COFFIN_PLACING}`),
              anchor: '#coffin_placing'
            },
            {
              label: this.$t(`enums.event.type.${eventType.COFFIN_CLOSING}`),
              anchor: '#coffin_closing'
            },
            {
              label: this.$t(`attributes.other_infos`),
              anchor: '#other_infos'
            }
          ],
          formType: ['normal', 'light'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        // {
        //   label: this.$t('global.insurance'),
        //   routeName: this.editionMode
        //     ? 'dossier_form_insurance_route_edit'
        //     : 'dossier_form_insurance_route',
        //   icon: ['fas', 'piggy-bank'],
        //   gate: this.cIsEditView,
        //   subItems: null,
        //   formType: ['normal'],
        //   countries: [
        //     Country.BELGIUM,
        //     Country.SWITZERLAND,
        //     Country.FRANCE,
        //     Country.NETHERLANDS,
        //     Country.SPAIN,
        //     Country.ITALY,
        //     Country.PORTUGAL,
        //     Country.GERMANY,
        //     Country.LUXEMBOURG
        //   ]
        // },
        {
          label: this.$t('global.particularities'),
          routeName: this.editionMode
            ? 'dossier_form_particularities_route_edit'
            : 'dossier_form_particularities_route',
          icon: ['fas', 'comment-question'],
          gate: this.cIsEditView,
          subItems: null,
          formType: ['normal', 'light'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.other_products'),
          routeName: this.editionMode
            ? 'dossier_form_other_products_route_edit'
            : 'dossier_form_other_products_route',
          icon: ['fas', 'tag'],
          gate: this.cIsEditView,
          subItems: null,
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.external_services'),
          routeName: this.editionMode
            ? 'dossier_form_external_services_route_edit'
            : 'dossier_form_external_services_route',
          icon: ['fas', 'share-nodes'],
          gate: this.cIsEditView,
          subItems: null,
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.other_events'),
          routeName: this.editionMode
            ? 'dossier_form_other_events_route_edit'
            : 'dossier_form_other_events_route',
          icon: ['far', 'calendar'],
          gate: this.cIsEditView,
          subItems: null,
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.mourning_reception'),
          routeName: 'dossier_form_mourning_reception_route',
          icon: ['fas', 'mug'],
          gate: false,
          subItems: null,
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.obituary'),
          routeName: 'dossier_form_obituary_route',
          icon: ['fas', 'newspaper'],
          gate: false,
          subItems: null,
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        }
      ]
    },
    secondMenu() {
      return [
        {
          label: this.$t('global.documents'),
          routeName: 'dossier_documents_router_route',
          icon: ['fas', 'file-lines'],
          gate: this.cIsEditView,
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.attachments'),
          routeName: 'dossier_form_attachments_route',
          icon: ['fas', 'paperclip'],
          gate: this.cIsEditView && this.$can('manage_dossier'),
          formType: ['normal', 'light'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.gallery'),
          routeName: 'dossier_gallery_list_route',
          icon: ['fas', 'image-portrait'],
          gate: this.cIsEditView && this.$can('manage_dossier'),
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.condolence'),
          routeName: 'dossier_form_condolence_route',
          icon: ['fas', 'feather'],
          gate: false,
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.invoicing'),
          routeName: 'dossier_form_invoicing_route',
          icon: ['fas', 'euro-sign'],
          gate: this.cIsEditView && this.$can('see_invoicing'),
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.item_management'),
          routeName: 'dossier_item_order_route',
          icon: ['fas', 'truck-clock'],
          gate: this.cIsEditView,
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        },
        {
          label: this.$t('global.tasks'),
          routeName: 'dossier_form_tasks_route',
          icon: ['fas', 'list-check'],
          gate: this.cIsEditView && this.$can('see_tasks'),
          formType: ['normal'],
          countries: [
            Country.BELGIUM,
            Country.SWITZERLAND,
            Country.FRANCE,
            Country.NETHERLANDS,
            Country.SPAIN,
            Country.ITALY,
            Country.PORTUGAL,
            Country.GERMANY,
            Country.LUXEMBOURG
          ]
        }
      ]
    },
    firstMenuEntries() {
      return this.firstMenu
        .filter((entry) => entry.gate)
        .filter(
          (entry) =>
            !this.$route?.meta?.form ||
            entry.formType.includes(this.$route.meta.form)
        )
        .filter((entry) => entry.countries.includes(this.defaultCountry))
    },
    secondMenuEntries() {
      return this.secondMenu
        .filter((entry) => entry.gate)
        .filter(
          (entry) =>
            !this.$route?.meta?.form ||
            entry.formType.includes(this.$route.meta.form)
        )
        .filter((entry) => entry.gate)
        .filter((entry) => entry.countries.includes(this.defaultCountry))
    },
    firstMenuHasPreviousEntry() {
      const index = this.firstMenuEntries.findIndex(
        (entry) => entry.routeName === this.$route.name
      )
      return index && index > 0
    },
    firstMenuHasNextEntry() {
      const index = this.firstMenuEntries.findIndex(
        (entry) => entry.routeName === this.$route.name
      )
      return index && index + 1 < this.firstMenuEntries.length
    },
    firstMenuPreviousEntry() {
      const index = this.firstMenuEntries.findIndex(
        (entry) => entry.routeName === this.$route.name
      )
      return this.firstMenuEntries[index - 1]
    },
    firstMenuNextEntry() {
      const index = this.firstMenuEntries.findIndex(
        (entry) => entry.routeName === this.$route.name
      )
      return this.firstMenuEntries[index + 1]
    },
    cIsEditView() {
      return this.$route.meta.type === 'edit'
    },
    cInformationRoute() {
      const route_name =
        this.$route.meta.form === 'normal'
          ? this.editionMode
            ? 'dossier_form_information_route_edit'
            : 'dossier_form_information_route'
          : 'dossier_light_form_information_route'
      return this.cIsCreateView ? 'dossier_form_new_route' : route_name
    }
  },
  methods: {}
}
